@import '@which/seatbelt/src/styles/styles';

.awMoreWrapper {
  display: grid;
  grid-auto-flow: column;
  @include sb-respond-to(large) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-start: 1;
    grid-column-end: 13;
    margin-bottom: $sb-spacing-xl;
    grid-gap: $sb-spacing-l;
    grid-row-gap: $sb-spacing-xl;
  }
  @include sb-respond-to(medium) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-start: 1;
    grid-column-end: 13;
    grid-gap: $sb-spacing-s;
    grid-row-gap: $sb-spacing-m;
    margin-bottom: $sb-spacing-l;
  }
  grid-template-columns: repeat(2, 1fr);
  grid-column-start: 1;
  margin-bottom: $sb-spacing-m;
  grid-column-end: 3;
  margin-top: $sb-spacing-l;
  grid-gap: $sb-spacing-xs;
  grid-row-gap: $sb-spacing-m;
}

.awCardsWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  @include sb-respond-to(large) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-start: 2;
    grid-column-end: 12;
    grid-gap: $sb-spacing-xl;
  }
  @include sb-respond-to(medium) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-start: 2 !important;
    grid-column-end: 12 !important;
    grid-gap: $sb-spacing-l;
  }
  grid-column-start: 1;
  grid-column-end: 3;
  grid-gap: $sb-spacing-m;
}

.cardsWrapper {
  grid-auto-rows: 1fr;
}
.awCardArticle {
  height: 100%;
}
.awMoreHeading {
  text-align: center;
}
