@import '~@which/seatbelt/src/styles/styles';

.cardRow {
  margin-bottom: 0;

  div[class*='ScrollWrapper'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.articleCard {
  h2 {
    @include sb-font-medium;
    @include sb-text-body;
  }
}

.viewAllLinkCard {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 79vw;

  @include sb-respond-to(medium-up) {
    width: 44vw;
  }

  @include sb-respond-to(large) {
    width: 30vw;
    max-width: 400px;
  }
}
