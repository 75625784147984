@import '~@which/seatbelt/src/styles/styles';

.collection {
  padding: $sb-spacing-xl 0;
  background-color: sb-color('sb-color-white');

  &.isEven {
    background-color: sb-color('sb-color-black-05');
  }
}
