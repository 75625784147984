@import '~@which/seatbelt/src/styles/styles';

.bannerSignUpWrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  @include sb-respond-to('medium-up') {
    grid-template-columns: repeat(12, 1fr);
  }
}

.bannerParagraph {
  margin-bottom: $sb-spacing-m;
}

.bannerSignUpShare {
  align-items: center;
  padding: 0 0 $sb-spacing-m 0;
  @include sb-respond-to('medium-up') {
    margin-top: $sb-spacing-m;
  }
}

.bannerSignUpShareBorderItems {
  border-top: 1px solid black;
  margin: 0 auto;
  width: 100%;
}

.bannerSignInfo {
  padding: $sb-spacing-xl 0;
  position: relative;
  z-index: 2;

  @include sb-respond-to('medium-up') {
    padding: $sb-spacing-m 0;
    justify-items: self-start;
  }
}

.bannerSignUpButtonWrapper {
  width: 100%;
  @include sb-respond-to('medium-up') {
    margin: 0;
    text-align: left;
  }
}

.bannerImageWrapper {
  display: none;

  @include sb-respond-to('medium-up') {
    display: inline-block;
    position: relative;
    padding-bottom: 0;

    img {
      background-color: transparent;
    }
  }
}

.bannerImage {
  width: 100vw;
  min-width: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include sb-respond-to('medium-up') {
    all: unset;
    width: 100%;
    min-width: 100%;
  }
}
