@import '~@which/seatbelt/src/styles/styles';

.myAppointmentHeader {
  &Title {
    margin-bottom: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-l;
    }
  }

  &Standfirst {
    margin-bottom: $sb-spacing-m;
  }

  &Jumplinks {
    align-items: flex-start;
    border: 0;
    display: flex;
    flex-direction: column;
    float: left;
  }

  &LinkContainer {
    display: flex;
    flex-direction: column;
    margin-top: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      align-items: center;
      margin-top: $sb-spacing-xl;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &Button {
    margin-top: $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      float: right;
    }
  }
}
