@import '~@which/seatbelt/src/styles/styles';

.formInputHidden {
  display: none;
}

.formCheckbox {
  margin: $sb-spacing-m 0;

  [class^='FormField__formFieldLabelWrapper'] {
    display: none;
  }

  [class^='FormField_formFieldLabelWrapper'] {
    display: none;
  }

  [class^='Checkbox__checkboxWrapper'] {
    max-width: 85%;

    @include sb-respond-to(medium-up) {
      max-width: auto;
    }
  }

  //SB-TODO - double underscore > single underscore
  [class^='Checkbox_checkboxWrapper'] {
    max-width: 85%;

    @include sb-respond-to(medium-up) {
      max-width: auto;
    }
  }

  &Error {
    color: $sb-colour-text-negative;
  }
}

.formTextarea {
  line-height: $sb-line-height-25;
  padding: $sb-spacing-m;
  width: 100%;

  &Error {
    border: 1px solid $sb-colour-text-negative;
  }
}

.formDropdown,
[class*='Dropdown_container']:not([class*='CategorySelection']) {
  width: 100%;
}
