@import '~@which/seatbelt/src/styles/styles';

.collection {
  &Wrapper {
    align-items: center;
  }

  &Title {
    margin-bottom: $sb-spacing-l;
  }

  &Featured {
    max-width: 380px;
    margin: 0 auto $sb-spacing-l;
    width: 100%;

    @include sb-respond-to(medium-up) {
      margin: 0;
    }
  }
}
