@import '~@which/seatbelt/src/styles/styles';

.myAppointment {
  @include sb-default-border-black-20;
  border-radius: $sb-border-radius-4;
  padding: $sb-spacing-xl 0;

  @include sb-respond-to(medium-up) {
    padding: $sb-spacing-xl;
  }

  &Button {
    white-space: nowrap;
    display: none;

    @include sb-respond-to(xlarge) {
      display: block;
    }
  }

  &ButtonLower {
    white-space: nowrap;
    display: inline-block;
    float: right;

    @include sb-respond-to(xlarge) {
      display: none;
    }

    @include sb-respond-to(small) {
      display: block;
      float: none;
      margin-top: $sb-spacing-m;
    }
  }

  &Title {
    margin: 110px 0 $sb-spacing-l;
  }

  &ButtonContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  &Description {
    margin-bottom: $sb-spacing-l;
  }

  &RefNumber {
    color: $sb-colour-text-muted;
  }

  &Details {
    display: flex;
    margin-top: $sb-spacing-xl;
    margin-bottom: 54px;
    max-width: 420px;
    justify-content: space-between;
  }

  &Detail {
    padding-right: $sb-spacing-m;
  }

  &NotEntitled {
    display: none;

    @include sb-respond-to(xlarge) {
      display: block;
    }
  }

  &NotEntitledLower {
    display: block;
    text-align: right;
    max-width: 260px;
    margin-left: auto;
    padding-top: 20px;

    @include sb-respond-to(xlarge) {
      display: none;
    }
  }
}
