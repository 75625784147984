@import '~@which/seatbelt/src/styles/styles';

.jumplinks {
  &Holder {
    ul {
      border-top: none;
      padding-top: 0;
      padding-bottom: 8px;

      @include sb-respond-to(medium-up) {
        margin-top: 0;
        padding: 34px 0 23px;
      }
    }
  }

  &Header {
    margin: 21px 0 0;

    @include sb-respond-to(medium-up) {
      display: none;
    }
  }
}
