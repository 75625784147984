@import '~@which/seatbelt/src/styles/styles';

.campaignsHistory {
  @include sb-respond-to(medium-up) {
    padding: $sb-spacing-3xl 0 34px;
  }

  padding: $sb-spacing-xl 0 $sb-spacing-l;
  position: relative;

  svg {
    background: sb-color('sb-color-brand-blue-20');
  }

  &Header {
    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-xl;
    }

    margin-bottom: $sb-spacing-l;
    text-align: center;
  }

  &Standfirst {
    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-l;
    }

    margin-bottom: 21px;
  }

  &Quote {
    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-xl;
    }

    p {
      font-family: $sb-font-family-regular;
      font-weight: 400;
    }

    [data-testid='author-job-title'] {
      font-family: $sb-font-family-medium;
    }

    margin-bottom: $sb-spacing-m;
  }

  &Body:not(:last-of-type) {
    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-xl;
    }

    margin-bottom: $sb-spacing-l;
  }

  &Intro {
    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-3xl;
    }

    margin-bottom: $sb-spacing-l;
  }

  &Card {
    @include sb-respond-to(large) {
      width: 300px;
      margin-bottom: $sb-spacing-xl;

      &:not(:last-child) {
        margin-right: $sb-spacing-l;
      }
    }

    > div {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    &:hover {
      box-shadow: none;
    }

    [data-testid='article-card-text-strapline'] {
      @include sb-respond-to(large) {
        margin-top: $sb-spacing-l;
      }
      margin-top: $sb-spacing-m;
    }

    [data-testid='article-card-text'] {
      @include sb-respond-to(medium-up) {
        padding-top: $sb-spacing-l;
      }
      padding-top: $sb-spacing-m;
    }

    a {
      pointer-events: none;
    }

    border-radius: 0;
    border: 0;
    margin-bottom: $sb-spacing-l;
  }

  &Label {
    cursor: pointer;
    margin-bottom: $sb-spacing-xs;

    &Container {
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    &Checked {
      font-weight: 700;
    }
  }

  &Radio {
    padding: 0;

    label {
      padding: 0;
    }

    &Container {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: fit-content;
      z-index: 1;

      > label > span {
        z-index: 2;
      }

      &:hover > label > span {
        background-size: 100% 1px;
      }
    }
  }

  &Line {
    border-bottom: 0;
    position: absolute;
    height: 1px;
    top: 33px;
    left: 25px;
    width: calc(100% - 50px);
  }

  &Item {
    &Section {
      position: relative;
    }

    &Hide {
      display: none;
    }

    &Show {
      @include sb-respond-to(medium-up) {
        margin-bottom: $sb-spacing-3xl;
        padding: 0 $sb-spacing-xl;
      }

      background-color: sb-color('sb-color-white');
      border-radius: $sb-border-radius-4;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      display: block;
      padding: 0 15px;
      margin-bottom: $sb-spacing-l;
    }

    &Container {
      @include sb-respond-to(large) {
        flex-direction: row;
      }

      display: flex;
      flex-direction: column;
    }

    &Header {
      @include sb-respond-to(medium-up) {
        padding-top: $sb-spacing-xl;
        margin-bottom: $sb-spacing-l;
      }

      font-family: $sb-font-family-bold;
      padding-top: $sb-spacing-l;
      margin-bottom: $sb-spacing-m;
    }

    &Standfirst {
      @include sb-respond-to(medium-up) {
        margin-bottom: $sb-spacing-xl;
      }

      margin-bottom: $sb-spacing-l;
    }
  }

  &Background {
    background: sb-color('sb-color-brand-blue-20');
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100vw;
    z-index: -1;
  }

  &ArrowUp {
    bottom: -34px;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 30px solid sb-color('sb-color-white');
    position: absolute;
    height: 0;
    width: 0;
  }
}
