@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  margin-top: $sb-spacing-xl;
  margin-bottom: 20px;

  @include sb-respond-to(medium-up) {
    margin-top: $sb-spacing-3xl;
    margin-bottom: 30px;
  }
}
