@import '@which/seatbelt/src/styles/styles';

.awNavWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  .navGridItem:not(:first-child) {
    padding-top: $sb-spacing-l;
  }

  .navGridItem:not(:last-child) {
    border-bottom: 1px solid sb-color("sb-color-black-41");
  }

  @include sb-respond-to(large) {
    grid-gap: $sb-spacing-l;

    .navGridItem:not(:first-child) {
      padding-top: 0;
    }

    .navGridItem:not(:last-child) {
      padding: 0;
    }
  }

  &EvenGridItems {
    @include sb-respond-to(large) {
      .navGridItem:nth-last-child(-n+2) {
        border-bottom: none;
      }
    }
  }
}

.awPromoWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  .navGridItem:not(:first-child) {
    padding-top: $sb-spacing-l;
  }

  .navGridItem:not(:last-child) {
    border-bottom: 1px solid sb-color("sb-color-black-41");
  }

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: $sb-spacing-l;

    .navGridItem:not(:first-child) {
      padding-top: 0;
    }

    .navGridItem:not(:last-child) {
      border-bottom: none;
    }

    .navGridItem:not(:last-child, :nth-last-child(2)) {
      border-bottom: 1px solid sb-color("sb-color-black-41");
    }
  }
}

.awPromoBlockWrapper {
  padding: $sb-spacing-xl;
  margin: 60px 0 50px 0;

  @include sb-respond-to(medium-up) {
    padding: $sb-spacing-xl 0;
  }
}

.widgetsStandfirst {
  margin-top: $sb-spacing-s;
}

.awArticleLink {
  display: inline-block;
  padding-bottom: $sb-spacing-m;
}
