@import '~@which/seatbelt/src/styles/styles';

.moneyPodcastWrapper {
  background-color: sb-color('sb-color-black-05');
  padding: $sb-spacing-xl 0;
}

.moneyPodcast {
  grid-auto-flow: column;
  grid-template-columns: repeat(12, 1fr);
}

.moneyNewsletterWrapper {
  grid-auto-flow: column;
  grid-template-columns: repeat(12, 1fr);
  margin-top: $sb-spacing-3xl;
}

.verticalHeader {
  margin: $sb-spacing-xl 0 48px;
}
