@import '~@which/seatbelt/src/styles/styles';

.campaignsHeader {
  max-width: 1440px;

  @include sb-respond-to(large) {
    min-height: 376px;
    margin-top: $sb-spacing-xl;
  }

  &Container {
    margin-left: 0;
    margin-right: 0;

    @include sb-respond-to(large) {
      min-height: 376px;
    }
  }

  @include sb-respond-to(medium-down) {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  [data-testid='picture-container'] {
    @include sb-respond-to(medium-down) {
      overflow: initial;
    }
  }

  &ImageContainer {
    div {
      height: 100%;
    }

    img {
      @include sb-respond-to(large) {
        height: 100%;
      }
    }
  }

  &Content {
    padding: $sb-spacing-l
      $sb-spacing-l $sb-spacing-l;

    @include sb-respond-to(large) {
      margin: 0 auto;
      max-width: 1440px;
      padding: $sb-spacing-xl
        $sb-spacing-xl $sb-spacing-3xl
        $sb-spacing-3xl;
    }

    h1 {
      margin: 0 0 $sb-spacing-m;

      @include sb-respond-to(medium-up) {
        margin: 0 0 $sb-spacing-l;
      }
    }
  }

  &Standfirst {
    margin: 0 0 $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      margin: 0 0 $sb-spacing-l;
      max-width: 528px;
    }
  }
}
