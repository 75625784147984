@import '~@which/seatbelt/src/styles/styles';

.awPromoBlockWrapper {
  background-color: sb-color('sb-color-black-05');
  width: 100%;
  padding: $sb-spacing-xl;
  margin: 60px 0 50px 0;

  @include sb-respond-to(medium-up) {
    padding: $sb-spacing-xl 0;
  }
}
