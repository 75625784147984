@import '~@which/seatbelt/src/styles/styles';

.myAppointments {
  margin-top: 80px;

  @include sb-respond-to(large) {
    margin-top: 120px;
  }

  &Title {
    padding-bottom: $sb-spacing-l;
  }

  &Standfirst {
    max-width: 690px;
  }

  &Appointment {
    padding-top: $sb-spacing-l;
  }

  &NoAppointments {
    color: $sb-colour-text-muted;
    text-align: center;
    margin-top: 50px;

    @include sb-respond-to(large) {
      margin-top: 76px;
    }
  }

  &ShowMore {
    display: flex;
    justify-content: center;
  }
}
