@import '~@which/seatbelt/src/styles/styles';

.campaignsSupporter {
  @include sb-respond-to(large) {
    padding: $sb-spacing-3xl 0 $sb-spacing-3xl;
  }

  position: relative;
  padding: $sb-spacing-xl 0 $sb-spacing-3xl;
  margin: auto;
  width: 100%;

  &Header {
    text-align: center;
    margin-bottom: $sb-spacing-m;
  }

  &Standfirst {
    @include sb-respond-to(large) {
      margin-bottom: $sb-spacing-xl;
    }

    text-align: center;
    margin-bottom: $sb-spacing-m;
  }

  &CharCount {
    @include sb-respond-to(large) {
      margin-bottom: $sb-spacing-m;
    }

    margin-bottom: $sb-spacing-s;
  }

  &Fields {
    margin: 0;
  }

  &Field {
    @include sb-respond-to(large) {
      margin-bottom: $sb-spacing-l;
    }

    margin-bottom: $sb-spacing-m;
  }

  &Background {
    background: sb-color('sb-color-black-01');
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100vw;
    z-index: -1;
  }

  &Toggle,
  &Optin {
    padding-left: 32px;
  }

  &Toggle {
    @include sb-font-size-line-height(16px, 20px);
    display: block;
    margin: 2px 0 $sb-spacing-xs;
  }

  &Checkbox {
    label {
      @include sb-font-medium;
      font-size: $sb-font-size-18;
    }
  }

  &ButtonWrapper {
    text-align: center;

    @include sb-respond-to(large) {
      margin-top: $sb-spacing-3xl;
    }

    margin-top: $sb-spacing-xl;
  }

  &SubmitButton {
    display: block;
    margin: auto;

    @include sb-respond-to(medium-up) {
      width: 203px;
    }
  }

  &TextareaCharCount {
    position: relative;
    bottom: $sb-spacing-m;

    &Error {
      color: sb-color('sb-color-which-red');
    }
  }

  &ErrorText {
    color: sb-color('sb-color-error-red');
    margin-top: $sb-spacing-xs;
  }

  &SubText {
    margin-top: $sb-spacing-s;
  }
}
